import { css } from "styled-components";
import { ViewportSize } from "../../content-blocks/container/types";

export const SmallBlock = css`
  grid-column: span 2;
  grid-row: span 2;

  @media (min-width: ${ViewportSize.TABLET}px) {
    grid-column: span 1;
    grid-row: span 1;
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-column: span 1;
    grid-row: span 1;
  }
`;

export const WideBlock = css`
  grid-column: span 2;
  grid-row: span 2;

  @media (min-width: ${ViewportSize.TABLET}px) {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-column: span 2;
    grid-row: span 2;
  }
`;

export const TallBlock = css`
  grid-column: span 2;
  grid-row: span 3;

  @media (min-width: ${ViewportSize.TABLET}px) {
    grid-column: span 2;
    grid-row: span 3;
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-column: span 2;
    grid-row: span 3;
  }
`;
