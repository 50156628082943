import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Seo } from "../components/seo";
import { LayoutDefault } from "../layouts/default";
import { FeaturedEntriesArticles } from "../components/featured-entries/articles";
import { FeaturedEntriesProjects } from "../components/featured-entries/projects";
import { ViewportSize } from "../components/grid/content-blocks/container/types";
import {
  ContentLayoutInterface,
  ContentBlockHandle,
} from "../components/content-blocks/content-layout/types";
import { TextBlockInterface } from "../components/content-blocks/text-block/types";

interface QueryData {
  data: any;
}

const HomePageContentSection = styled.section``;

const IntroContainer = styled.div`
  display: grid;
  padding: 0 1rem;
  margin: auto;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-width: var(--content-width-max);

  section {
    margin: 0;
  }

  p {
    margin-top: 2rem;
  }

  > :nth-child(1) {
    p:first-child {
      font-size: 1.5rem;
      line-height: 1.6;
      margin-top: 0;
    }

    p + p {
      font-size: 1.25rem;
    }
  }

  @media (min-width: ${ViewportSize.TABLET}px) {
    padding: 1rem;
    grid-row-gap: 5rem;
    width: var(--content-width-tablet);

    > :nth-child(1) {
      p:first-child {
        font-size: 1.875rem;
        line-height: 1.6;
        margin-top: 0;
      }

      p + p {
        font-size: 1.375rem;
      }
    }
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-template-columns: 50% 50%;
    grid-column-gap: 1rem;
    grid-row-gap: 5rem;
    width: var(--content-width-desktop);

    > :nth-child(1) {
      padding-right: 2rem;

      p:first-child {
        margin-top: 2rem;
      }
    }
  }
`;

const ProjectContainer = styled.div`
  padding: 1rem;
  max-width: 1400px;
  margin: auto;

  @media (min-width: ${ViewportSize.DESKTOP}px) {
  }
`;

const HomePageContent = (props: ContentLayoutInterface) => {
  const { contentEditorFields } = props;
  const textBlocks = contentEditorFields.filter(
    (contentBlock) => contentBlock.typeHandle === ContentBlockHandle.TEXT
  );
  return textBlocks.map((textBlock) => {
    const { richTextContent, uid } = textBlock as TextBlockInterface;
    return (
      <section
        key={uid}
        dangerouslySetInnerHTML={{
          __html: richTextContent,
        }}
      ></section>
    );
  });
};

export const IndexPage = (props: QueryData) => (
  <LayoutDefault>
    <Seo title="Home" entry={props.data.craft.entry} />
    <IntroContainer>
      {HomePageContent(props.data.craft.entry)}
      <FeaturedEntriesArticles />
    </IntroContainer>
    <ProjectContainer>
      <FeaturedEntriesProjects />
    </ProjectContainer>
  </LayoutDefault>
);

export const query = graphql`
  query HomePageQuery {
    craft {
      entry(section: "homePage") {
        ...entryCommonFields
        ... on craft_homePage_homePage_Entry {
          contentEditorFields {
            ...textBlock
          }
        }
      }
    }
  }
`;

export default IndexPage;
