import React, { useState, useEffect, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { LazyImageInterface } from "./types";

const LazyLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  &:before {
    content: "";
    color: #aaa;
    position: absolute;
    width: 100%;
    height: 3rem;
    font-size: 5rem;
    line-height: 0;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: lazy-loading 2s ease infinite;
  }

  @keyframes lazy-loading {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }
`;

const PictureSources = styled.picture`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  width: ${(props) => props.theme.width}px;
  margin: auto;
  background: whitesmoke;
`;

const StyledImage = styled.img`
  width: ${(props) => props.theme.width}px;
  height: auto;
  max-width: 100%;
  margin: auto;
`;

export const LazyImage: React.FunctionComponent<LazyImageInterface> = (
  props: LazyImageInterface
) => {
  const {
    mediaTitle,
    mediaAltText,
    width,
    height,
    gatsbyImageData,
    uid,
  } = props;

  const { layout, placeholder, images } = gatsbyImageData;
  const base64 = placeholder?.fallback;
  const { fallback, sources } = images;
  const fallbackSrc = fallback?.src;
  const fallbackSrcSet = fallback?.srcSet;
  const fallbackSizes = fallback?.sizes;

  const [loading, setLoading] = useState(true);

  const loaderRef = useRef();

  const observerCallback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setLoading(false);
        observer.unobserve((loaderRef.current as unknown) as HTMLDivElement);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);
    if (loaderRef && loaderRef.current) {
      observer.observe((loaderRef.current as unknown) as HTMLDivElement);
    }
    return () => {
      if (loaderRef && loaderRef.current) {
        observer.unobserve((loaderRef.current as unknown) as HTMLDivElement);
      }
    };
  }, []);

  return (
    <ThemeProvider theme={{ loading, width, height, base64, layout }}>
      <LazyLoaderContainer
        ref={(loaderRef as unknown) as React.MutableRefObject<HTMLDivElement>}
      >
        <PictureSources>
          {sources?.map((source, idx) => (
            <source
              key={`source-${uid}-${idx}`}
              srcSet={loading ? base64 : source?.srcSet}
              sizes={source?.sizes}
              type={source?.type}
            />
          ))}
          <StyledImage
            src={loading ? base64 : fallbackSrc}
            srcSet={fallbackSrcSet}
            sizes={fallbackSizes}
            title={mediaTitle}
            alt={mediaAltText}
            width={width}
            height={height}
            itemProp="image"
          />
        </PictureSources>
      </LazyLoaderContainer>
    </ThemeProvider>
  );
};
