import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { EntryLists } from "../../entry-lists";

const featuredEntriesQuery = graphql`
  query FeaturedEntriesArticlesQuery {
    craft {
      entries(section: ["articles"], limit: 3) {
        ... on craft_articles_stories_Entry {
          ...entryCommonFields
          entryTags {
            uid
            id
            slug
            title
          }
          contentEditorFields {
            ...textBlock
          }
        }
      }
    }
  }
`;

export const FeaturedEntriesArticles: React.FunctionComponent = () => {
  return (
    <StaticQuery
      query={featuredEntriesQuery}
      render={(data) => {
        const {
          craft: { entries },
        } = data;
        return <EntryLists entries={entries} />;
      }}
    />
  );
};
