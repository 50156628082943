import React, { FunctionComponent } from "react";
import styled, { StyledComponent } from "styled-components";
import { TileBlockInterface } from "./types";
import { SmallBlock, WideBlock, TallBlock } from "./mixins";

export const StyledTileBlock: StyledComponent<"div", any> = styled.div`
  &:nth-child(n) {
    ${WideBlock}
  }

  &:nth-child(2n),
  &:nth-child(3n) {
    ${SmallBlock}
  }

  &:nth-child(4n) {
    ${TallBlock}
  }

  &:nth-child(5n),
  &:nth-child(6n) {
    ${SmallBlock}
  }

  &:nth-child(7n) {
    ${WideBlock}
  }

  &:nth-child(8n),
  &:nth-child(9n),
  &:nth-child(10n),
  &:nth-child(11n),
  &:nth-child(12n),
  &:nth-child(13n) {
    ${SmallBlock}
  }
`;

export const TileBlock: FunctionComponent<TileBlockInterface> = (
  props: TileBlockInterface
) => {
  const { children } = props;

  return <StyledTileBlock>{children}</StyledTileBlock>;
};
