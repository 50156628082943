import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import { FeaturedEntriesInterface } from "../types";
import { TileContainer } from "../../grid/tile/container";
import { TileBlock } from "../../grid/tile/block";
import {
  EntryFeaturedImageInterface,
  EntryInterface,
} from "../../../types/entries";
import { Helmet } from "react-helmet";
import { hexToRgb } from "../../../utils/colors/hex-to-rgb";
import { LazyImage } from "../../lazy-image";
import {
  MediaContentInterface,
  MediaContentImageFileInterface,
} from "../../content-blocks/media-block/types";

const featuredEntriesQuery = graphql`
  query FeaturedEntriesProjectsQuery {
    craft {
      entries(section: ["projects"], level: 1, limit: 13) {
        ... on craft_projects_showcase_Entry {
          ...entryCommonFields
          featuredHeadingColor
          featuredTextColor
          featuredBackgroundColor
          featuredMarkup
          featuredStyles
          featuredImage {
            ... on craft_media_Asset {
              uid
              id
              url
              small: url(immediately: true, transform: "tileblocksingle")
              large: url(immediately: true, transform: "tileblocklargewide")
              tall: url(immediately: true, transform: "tileblocklargetall")
              title
              mediaTitle
              mediaAltText
              width
              height
              imageFileSmall {
                childImageSharp {
                  gatsbyImageData(
                    width: 330
                    formats: [AUTO, JPG, WEBP, AVIF]
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
              imageFileLarge {
                childImageSharp {
                  gatsbyImageData(
                    width: 676
                    formats: [AUTO, JPG, WEBP, AVIF]
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
              imageFileTall {
                childImageSharp {
                  gatsbyImageData(
                    width: 676
                    formats: [AUTO, JPG, WEBP, AVIF]
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          entryTags {
            uid
            id
            slug
            title
          }
        }
      }
    }
  }
`;

const ProjectContainer = styled.div``;

const StyledProjectFigure = styled.figure`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledProjectTitle = styled.figcaption`
  padding: 1rem;
  display: flex;
  flex-flow: column;
  position: absolute;
  background: rgba(
    ${(props) =>
      hexToRgb(props.theme.featuredBackgroundColor).join(",") ?? "0,0,0"},
    0.75
  );
  font-family: var(--font-family-heading);
  font-weight: 600;
  line-height: 1.2;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const StyledProjectTags = styled.div`
  font-size: 0.8rem;
  margin-top: 0.25rem;
  line-height: 1.6;
`;

const StyledProjectEntry = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${(props) => props.theme.featuredTextColor ?? "#fff"};
  overflow: hidden;

  img {
    transition: filter 0.3s ease;
    width: 100%;
  }

  &:hover,
  &:focus {
    img {
      filter: blur(5px) saturate(0) brightness(0.5);
    }

    ${StyledProjectTitle} {
      opacity: 1;
    }
  }
`;

const StyledFeatureMarkup = styled.div`
  width: 100%;
  height: 100%;
`;

const buildProjectTiles = (items: EntryInterface[]) =>
  items.map((item: EntryInterface, idx: number) => {
    const {
      title,
      uri,
      uid,
      featuredHeadingColor,
      featuredTextColor,
      featuredBackgroundColor,
      featuredImage,
      featuredMarkup,
      featuredStyles,
      entryTags,
    } = item;

    const imageUrl = (idx: number): string => {
      return [
        "large",
        "small",
        "small",
        "tall",
        "small",
        "small",
        "large",
        "small",
        "small",
        "small",
        "small",
        "small",
        "small",
      ][idx];
    };
    const imageSize: string = imageUrl(idx);
    const image: EntryFeaturedImageInterface | undefined = featuredImage?.[0];
    const sharpImage = item.featuredImage as MediaContentInterface[] &
      EntryFeaturedImageInterface[];
    const getImageFile = (
      imageSize: string
    ): MediaContentImageFileInterface => {
      switch (imageSize) {
        case "small":
          return (sharpImage[0]
            .imageFileSmall as unknown) as MediaContentImageFileInterface;
        case "large":
          return (sharpImage[0]
            .imageFileLarge as unknown) as MediaContentImageFileInterface;
        case "tall":
          return (sharpImage[0]
            .imageFileTall as unknown) as MediaContentImageFileInterface;
        default:
          return (sharpImage[0]
            .imageFileSmall as unknown) as MediaContentImageFileInterface;
      }
    };
    const imageFile: MediaContentImageFileInterface = getImageFile(imageSize);
    const { gatsbyImageData } = imageFile.childImageSharp;

    let imageTag = null;

    if (image) {
      const { mediaAltText } = image;
      imageTag = (
        <LazyImage
          uid={image.uid}
          gatsbyImageData={gatsbyImageData}
          mediaTitle={`Image for project ${title}`}
          mediaAltText={mediaAltText}
          width={image.width}
          height={image.height}
        />
      );
    }

    const tags =
      entryTags && entryTags.length
        ? entryTags.map((tag) => tag.title).join(", ")
        : "";

    return (
      <TileBlock key={uid}>
        <ThemeProvider
          theme={{
            featuredHeadingColor,
            featuredTextColor,
            featuredBackgroundColor,
            featuredImage,
          }}
        >
          <StyledProjectEntry to={`/${uri}`}>
            {!featuredMarkup && (
              <StyledProjectFigure>
                {imageTag}
                <StyledProjectTitle>
                  {title}
                  {tags && <StyledProjectTags>{tags}</StyledProjectTags>}
                </StyledProjectTitle>
              </StyledProjectFigure>
            )}
            {featuredMarkup && (
              <StyledFeatureMarkup
                dangerouslySetInnerHTML={{
                  __html: featuredMarkup,
                }}
              ></StyledFeatureMarkup>
            )}
          </StyledProjectEntry>
        </ThemeProvider>
        {featuredStyles && (
          <Helmet>
            <style type="text/css">{featuredStyles}</style>
          </Helmet>
        )}
      </TileBlock>
    );
  });

export const ProjectEntries: React.FunctionComponent<FeaturedEntriesInterface> = (
  props: FeaturedEntriesInterface
) => {
  const { entries } = props;
  const projectTiles = buildProjectTiles(entries);

  return (
    <ProjectContainer>
      <TileContainer>{projectTiles}</TileContainer>
    </ProjectContainer>
  );
};

export const FeaturedEntriesProjects: React.FunctionComponent = () => {
  return (
    <StaticQuery
      query={featuredEntriesQuery}
      render={(data) => {
        const {
          craft: { entries },
        } = data;
        return <ProjectEntries entries={entries} />;
      }}
    />
  );
};
