import { RgbValue } from "./types";
export const hexToRgb = (hex: string): RgbValue => {
  if (!hex) return [0, 0, 0];
  let hexValues = hex;
  if (hex[0] === "#") hexValues = hexValues.slice(1);
  if (hexValues.length === 3) {
    const [r, g, b] = hexValues.split("");
    return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
  }
  if (hexValues.length === 6) {
    const [r0, r1, g0, g1, b0, b1] = hexValues.split("");
    return [
      parseInt(`${r0}${r1}`, 16),
      parseInt(`${g0}${g1}`, 16),
      parseInt(`${b0}${b1}`, 16),
    ];
  }
  return [0, 0, 0];
};
