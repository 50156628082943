import React, { FunctionComponent } from "react";
import styled, { StyledComponent } from "styled-components";
import { TileInterface } from "./types";
import { ViewportSize } from "../../content-blocks/container/types";

const StyledTileContainer: StyledComponent<"div", any> = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);

  @media (min-width: ${ViewportSize.TABLET}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
`;

export const TileContainer: FunctionComponent<TileInterface> = (
  props: TileInterface
) => {
  const { children } = props;

  return <StyledTileContainer>{children}</StyledTileContainer>;
};
